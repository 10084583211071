import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";

import {
  DocumentItem,
  FormOneItem,
  FormThreeItem,
  PostulationFormItem,
  PostulationItem,
  PostulationState,
  Availability,
} from "@lib/store/types/index";
import {
  emptyPostulation,
  emptyAvailableFosfec,
  emptyMenuAccordion,
  dataSideMenu,
} from "@lib/utils/helpers";

import { PostulationType, newMainMenuFosfec } from "@lib/utils/constants";
import { basePath } from "@lib/utils/constants";

const initialState: PostulationState = {
  postulations: [],
  postulationId: "",
  postulationForm: emptyPostulation(),
  postulationDocs: [],
  documentWithoutAssignment: [],
  postulationType: PostulationType.fosfec,
  isFosfecAvailability: emptyAvailableFosfec(),
  sending: false,
  formState: "OPEN",
  forwardTo: "1",
  loading: false,
  alertSuccess: false,
  alertDelete: false,
  alertAvailability: false,
  alertFosfecForm: false,
  alertFosfecAppointmentForm: false,
  actionSave: "",
  managementDate: "",
  stateName: "",
  sideMenu: dataSideMenu(),
};

const postulationSlice = createSlice({
  name: "document",
  initialState,
  reducers: {
    loadPostulation: () => initialState,
    loadActivePostulation: (state) => ({
      ...state,
      loading: true,
    }),
    loadPostulationsSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{
        postulation: PostulationItem[];
        form: PostulationFormItem;
        id: string;
        step: string;
        managementDate: string;
      }>
    ) => ({
      ...state,
      postulations: payload.postulation,
      postulationForm: payload.form,
      postulationId: payload.id,
      forwardTo: payload.step,
      managementDate: payload.managementDate,
      loading: false,
    }),
    loadPostulationsError: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      error: payload,
      loading: false,
    }),
    savePostulationStepOne: (
      state,
      { payload }: PayloadAction<{ action: string; values: FormOneItem }>
    ) => ({
      ...state,
      postulationForm: {
        ...state.postulationForm,
        formOne: payload.values,
      },
      actionSave: payload.action,
      sending: true,
    }),
    savePostulationStepThree: (
      state,
      { payload }: PayloadAction<{ action: string; values: FormThreeItem }>
    ) => ({
      ...state,
      postulationForm: {
        ...state.postulationForm,
        formThree: payload.values,
      },
      actionSave: payload.action,
      sending: true,
    }),
    saveCompletePostulation: (state) => ({
      ...state,
      postulationForm: {
        ...state.postulationForm,
        sideMenu: dataSideMenu(),
      },
      formState: "SENT",
      sending: true,
    }),
    sendingPostulationSuccess: (
      state,
      { payload }: PayloadAction<{ id: string; form: PostulationFormItem }>
    ) => ({
      ...state,
      sending: false,
      postulationId: payload.id,
      postulationForm: payload.form,
    }),
    sendingPostulationError: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      sending: false,
      error: payload,
    }),
    sendPensionRequired: (
      state,
      {
        payload,
      }: PayloadAction<{
        yesIHave: boolean;
        dontHave: boolean;
      }>
    ) => ({
      ...state,
      postulationForm: {
        ...state.postulationForm,
        formMenu: {
          ...state.postulationForm.formMenu,
          statePensionYesIHave: payload.yesIHave,
          statePensionDontHave: payload.dontHave,
        },
      },
      loading: false,
    }),
    sendPublicEmploymentRequired: (
      state,
      {
        payload,
      }: PayloadAction<{
        yesIHave: boolean;
        dontHave: boolean;
      }>
    ) => ({
      ...state,
      postulationForm: {
        ...state.postulationForm,
        formMenu: {
          ...state.postulationForm.formMenu,
          statePublicEmploymentYesIHave: payload.yesIHave,
          statePublicEmploymentDontHave: payload.dontHave,
        },
      },
      loading: false,
    }),

    sendUnemploymentSubsidy: (
      state,
      {
        payload,
      }: PayloadAction<{
        yesIHave: boolean;
        dontHave: boolean;
      }>
    ) => ({
      ...state,
      postulationForm: {
        ...state.postulationForm,
        formMenu: {
          ...state.postulationForm.formMenu,
          stateUnemploymentSubsidyYesIHave: payload.yesIHave,
          stateUnemploymentSubsidyDontHave: payload.dontHave,
        },
      },
      loading: false,
    }),
    loadPostulationByType: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      postulationType: payload,
      loading: true,
    }),

    sendStepCurrent: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      forwardTo: payload,
      sending: false,
    }),
    sendStateAlertSuccess: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      alertSuccess: payload,
    }),
    sendStateAlertDelete: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      alertDelete: payload,
    }),
    sendSeveranceFund: (
      state,
      {
        payload,
      }: PayloadAction<{
        yesIHave: boolean;
        dontHave: boolean;
      }>
    ) => ({
      ...state,
      postulationForm: {
        ...state.postulationForm,
        formMenu: {
          ...state.postulationForm.formMenu,
          stateSeveranceFundYesIHave: payload.yesIHave,
          stateSeveranceFundDontHave: payload.dontHave,
        },
      },
      loading: false,
    }),
    sendSeveranceProtection: (
      state,
      {
        payload,
      }: PayloadAction<{
        yesIHave: boolean;
        dontHave: boolean;
      }>
    ) => ({
      ...state,
      postulationForm: {
        ...state.postulationForm,
        formMenu: {
          ...state.postulationForm.formMenu,
          stateSeveranceProtectionYesIHave: payload.yesIHave,
          stateSeveranceProtectionDontHave: payload.dontHave,
        },
      },
      loading: false,
    }),
    savePostulationDocument: (
      state,
      { payload }: PayloadAction<DocumentItem>
    ) => ({
      ...state,
      postulationDocs: [payload, ...state.postulationDocs],
    }),
    loadFosfecAvailability: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      postulationType: payload,
      loading: true,
    }),
    loadFosfecAvailabilitySuccess: (
      state,
      { payload }: PayloadAction<Availability>
    ) => ({
      ...state,
      isFosfecAvailability: payload,
      loading: false,
    }),
    loadFosfecAvailabilityError: (
      state,
      { payload }: PayloadAction<string>
    ) => ({
      ...state,
      error: payload,
      loading: false,
    }),
    loadFosfecAppointmentAvailabilityError: (
      state,
      { payload }: PayloadAction<string>
    ) => ({
      ...state,
      error: payload,
      loading: false,
    }),
    loadModalAvailability: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      alertAvailability: payload,
      loading: false,
    }),
    loadModalFosfecForm: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      alertFosfecForm: payload,
      loading: false,
    }),
    loadModalFosfecAppointmentForm: (
      state,
      { payload }: PayloadAction<boolean>
    ) => ({
      ...state,
      alertFosfecAppointmentForm: payload,
      loading: false,
    }),
    sendPrivacyPoliciesCheck: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      postulationForm: {
        ...state.postulationForm,
        formMenu: {
          ...state.postulationForm.formMenu,
          statePrivacyPoliciesCheck: payload,
        },
      },
      loading: false,
    }),
    sendSwornStatementCheck: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      postulationForm: {
        ...state.postulationForm,
        formMenu: {
          ...state.postulationForm.formMenu,
          stateSwornStatementCheck: payload,
        },
      },
      loading: false,
    }),
    sendNotificationCheck: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      postulationForm: {
        ...state.postulationForm,
        formMenu: {
          ...state.postulationForm.formMenu,
          stateNotificationCheck: payload,
        },
      },
      loading: false,
    }),
    loadEnableFosfecForm: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      idPostulan: payload,
      loading: true,
    }),
    loadEnableFosfecFormSuccess: (
      state,
      { payload }: PayloadAction<boolean>
    ) => ({
      ...state,
      alertFosfecForm: payload,
      loading: false,
    }),
    loadEnableFosfecFormError: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      error: payload,
      loading: false,
    }),

    loadEnableFosfecAppointmentForm: (
      state,
      { payload }: PayloadAction<string>
    ) => ({
      ...state,
      idPostulan: payload,
      loading: true,
    }),
    loadEnableFosfecAppointmentFormSuccess: (
      state,
      { payload }: PayloadAction<boolean>
    ) => ({
      ...state,
      alertFosfecAppointmentForm: payload,
      loading: false,
    }),
    loadEnableFosfecAppointmentFormError: (
      state,
      { payload }: PayloadAction<string>
    ) => ({
      ...state,
      error: payload,
      loading: false,
    }),
    savePostulationAdjustment: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      sending: true,
      postulationId: payload,
      forwardTo: `/steps/InformationPersonalOneNew`,
    }),
    updatePostulationState: (
      state,
      {
        payload,
      }: PayloadAction<{ postulationId: string; postulationState: string }>
    ) => ({
      ...state,
      loading: true,
      postulationId: payload.postulationId,
      stateName: payload.postulationState,
    }),

    updatePostulationStateSuccess: (
      state,
      { payload }: PayloadAction<PostulationItem>
    ) => {
      state.postulations = state.postulations.map((post) =>
        post.id === payload.id
          ? {
              ...post,
              postulation_state: payload.postulation_state,
              loading: false,
            }
          : post
      );
    },
    updatePostulationStateError: (
      state,
      { payload }: PayloadAction<string>
    ) => ({
      ...state,
      error: payload,
      loading: false,
    }),

    deleteDocumentsByType: (state, { payload }: PayloadAction<string>) => {
      state.postulationDocs = state.postulationDocs.filter(
        (doc) => doc.documentation_type !== payload
      );
    },
    setPostulationDocument: (
      state,
      { payload }: PayloadAction<DocumentItem[]>
    ) => ({
      ...state,
      postulationDocs: payload,
    }),
    setSectionOne: (state, { payload }: PayloadAction<number>) => ({
      ...state,
      postulationForm: {
        ...state.postulationForm,
        formMenuAccordion: {
          ...state.postulationForm.formMenuAccordion,
          sectionOne: {
            ...state.postulationForm?.formMenuAccordion?.sectionOne,
            isVisible: true,
            id: payload,
          },
        },
      },
    }),
    setSectionTwo: (state, { payload }: PayloadAction<number>) => ({
      ...state,
      postulationForm: {
        ...state.postulationForm,
        formMenuAccordion: {
          ...state.postulationForm.formMenuAccordion,
          sectionTwo: {
            ...state.postulationForm?.formMenuAccordion?.sectionTwo,
            isVisible: true,
            id: payload,
          },
        },
      },
    }),
    setSectionThree: (state, { payload }: PayloadAction<number>) => ({
      ...state,
      postulationForm: {
        ...state.postulationForm,
        formMenuAccordion: {
          ...state.postulationForm.formMenuAccordion,
          sectionThree: {
            ...state.postulationForm?.formMenuAccordion?.sectionThree,
            isVisible: true,
            id: payload,
          },
        },
      },
    }),
    setSectionFour: (state, { payload }: PayloadAction<number>) => ({
      ...state,
      postulationForm: {
        ...state.postulationForm,
        formMenuAccordion: {
          ...state.postulationForm.formMenuAccordion,
          sectionFour: {
            ...state.postulationForm?.formMenuAccordion?.sectionFour,
            isVisible: true,
            id: payload,
          },
        },
      },
    }),

    updateDataSideMenu: (
      state,
      { payload }: PayloadAction<{ id: string; enabled: boolean; path: string }>
    ) => {
      if (state.postulationForm.sideMenu == undefined) {
        console.log("ingreso");
        state.postulationForm.sideMenu = dataSideMenu();
      }

      state.postulationForm.sideMenu = state.postulationForm?.sideMenu?.map(
        (post) =>
          post.id === payload.id
            ? {
                ...post,
                enabled: payload.enabled,
                path: payload.path,
              }
            : post
      );
    },
    setDataSideMenu: (state) => ({
      ...state,
      sideMenu: dataSideMenu(),
      postulationForm: {
        ...state.postulationForm,
        sideMenu: dataSideMenu(),
      },
    }),
    loadDocumentsByPostulation: (state) => ({
      ...state,
      loading: true,
    }),
    loadDocumentsByPostulationSuccess: (
      state,
      { payload }: PayloadAction<DocumentItem[]>
    ) => ({
      ...state,
      postulationDocs: payload,
      loading: false,
    }),
    loadDocumentsByPostulationError: (
      state,
      { payload }: PayloadAction<string>
    ) => ({
      ...state,
      error: payload,
      loading: false,
    }),
    sendExpressAuthorizationCheck: (
      state,
      {
        payload,
      }: PayloadAction<{
        stateExpressAuthorizationCheck: boolean | undefined;
      }>
    ) => ({
      ...state,
      postulationForm: {
        ...state.postulationForm,
        formMenu: {
          ...state.postulationForm.formMenu,
          stateExpressAuthorizationCheck:
            payload.stateExpressAuthorizationCheck,
        },
      },
      sending: true,
    }),
  },
});
export const {
  loadPostulation,
  loadActivePostulation,
  loadPostulationsSuccess,
  loadPostulationsError,
  savePostulationStepOne,
  sendingPostulationSuccess,
  sendingPostulationError,
  sendPensionRequired,
  sendUnemploymentSubsidy,
  savePostulationStepThree,
  saveCompletePostulation,
  loadPostulationByType,
  sendStepCurrent,
  sendStateAlertSuccess,
  sendStateAlertDelete,
  sendSeveranceFund,
  sendSeveranceProtection,
  sendPublicEmploymentRequired,
  savePostulationDocument,
  loadFosfecAvailability,
  loadFosfecAvailabilitySuccess,
  loadFosfecAvailabilityError,
  loadModalAvailability,
  loadModalFosfecForm,
  sendPrivacyPoliciesCheck,
  sendSwornStatementCheck,
  sendNotificationCheck,
  savePostulationAdjustment,
  loadEnableFosfecForm,
  loadEnableFosfecFormSuccess,
  loadEnableFosfecFormError,
  loadEnableFosfecAppointmentForm,
  loadEnableFosfecAppointmentFormSuccess,
  loadEnableFosfecAppointmentFormError,
  loadFosfecAppointmentAvailabilityError,
  loadModalFosfecAppointmentForm,
  updatePostulationState,
  updatePostulationStateSuccess,
  updatePostulationStateError,
  deleteDocumentsByType,
  setPostulationDocument,
  setSectionOne,
  setSectionTwo,
  setSectionThree,
  setSectionFour,
  updateDataSideMenu,
  setDataSideMenu,
  loadDocumentsByPostulation,
  loadDocumentsByPostulationSuccess,
  loadDocumentsByPostulationError,
  sendExpressAuthorizationCheck,
} = postulationSlice.actions;

export default postulationSlice.reducer;
